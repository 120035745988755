import React from 'react';

import * as styles from './index.module.scss';

const Accordion = ({title, children}) => (
    <div className={styles.accordion}>
        {title && typeof title !== 'undefined' && title.length > 0 && <h3 className={styles.accordionTitle}>{title}</h3>}
        <ul>
            {children}
        </ul>
    </div>
);

export default Accordion;