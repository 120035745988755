import React from "react";
import * as styles from './index.module.scss';

const getColor = (colorData,selected) => {
  let color,selectedColor;

  switch (colorData) {
    case 'black':
      color = styles.black;
      selectedColor = styles.white;
      break;
    case 'white':
      color = styles.white;
      selectedColor = styles.green;
      break;
    case 'clear':
      color = styles.clear;
      selectedColor = styles.green;
      break;
    case 'burrrometerbtn':
      color = styles.burrrometerbtn;
      selectedColor = styles.white;
      break;
    default:
      color = styles.green;
      selectedColor = styles.white;
      break;
  }

  return (selected) ? selectedColor : color;
}

const getType = (typeData) => {
  let type = null;

  switch (typeData) {
    case 'locale':
      type = styles.locale;
      break;
    case 'centered':
      type = styles.centered;
      break;
    case 'locale centered':
      type = `${styles.locale} ${styles.centered}`;
      break;
    default:
      break;
  }

  return type;
}

export const Button = ({
    id,
    elementType,
    colorData,
    typeData,
    href,
    content,
    alt,
    selected,
    clickEvent,
    additionalClasses,
    fullWidth,
    opensNewTab = false,
    size
  }) => {
  let color = getColor(colorData,selected);
  let type = getType(typeData);

  let classes = `${styles.btn} ${color} ${type} ${additionalClasses} ${styles[size]}`;

  if (fullWidth === true)
    classes += ` ${styles.fullWidth}`

  if (elementType === 'button')
    return <button id={id} onClick={clickEvent} className={classes} alt={alt}>{content}</button>;
  else if (elementType === 'span')
    return <span id={id} onClick={clickEvent} className={classes} alt={alt}>{content}</span>;
  else
    return <a id={id} className={classes} href={href} alt={alt} target={`${opensNewTab ? '_blank' : '_self'}`}>{content}</a>
}