import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";

import AudienceContext from "../../../contexts/audienceContext";

import * as styles from "./index.module.scss";

const AudiencesBar = ({ audiences, mobile }) => {
    const { activeAudience, setActiveAudience } = useContext(AudienceContext);
    const [isLoading, setIsLoading] = useState(false);

    function triggerLoad () {
        const timer = setTimeout(() => {
            setIsLoading(false);

            if (isLoading) {
                let selectedAudience = audiences.find(audience => audience.name === activeAudience);

                navigate(`${selectedAudience.url}`);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }

    useEffect(() => {
        triggerLoad();
    }, [activeAudience]);

    const handleChange = (event) => {
        const selectedSlug = event.target.hasAttribute("data-audience-slug") ? event.target.getAttribute("data-audience-slug") : event.target.value;
        const selectedAudience = audiences.find(audience => audience.slug === selectedSlug);

        if (selectedAudience.name === activeAudience) {
            setIsLoading(true);
            triggerLoad();
        } else {
            if (selectedAudience && selectedAudience.url && typeof window !== "undefined") {
                setIsLoading(true);
                setActiveAudience(selectedAudience.name);
            }
        }
    };

    if (mobile) {
        return (
            <>
                <select className={styles.mobileAudiencesBar} onChange={handleChange}>
                    {audiences.map(audience => (
                        <option key={audience?.slug} value={audience?.slug} selected={audience?.name === activeAudience}>
                            {audience?.name}
                        </option>
                    ))}
                </select>
                {isLoading && <div className={styles.loadingScreen}><div className={styles.loadingScreenSpinner}></div></div>}
            </>
        );
    }

    return (
        <>
            <ul className={styles.audiencesBar}>
                {audiences.map(audience => (
                    <li
                        key={audience?.name}
                        className={activeAudience === audience?.name ? styles.audiencesBarItemActive : ''}
                        onClick={handleChange}
                        data-audience-slug={audience?.slug}
                        data-audience-name={audience?.name}
                        data-audience-active={activeAudience}
                        data-audience-matches={activeAudience === audience?.name}
                    >
                        {audience?.name}
                    </li>
                ))}
            </ul>
            {isLoading && <div className={styles.loadingScreen}><div className={styles.loadingScreenSpinner}></div></div>}
        </>
    );
}

export default AudiencesBar;