import React from "react";

import AudienceProvider from "./src/contexts/audienceProvider";
import { Layout } from "./src/components/layout";

const LayoutWrapper = ({ element, props }) => {
  const isFormLandingPage = props.pageContext?.formLandingPage;
  let haveLayout = true; // Show header & footer by default
  
  if (props?.location?.pathname === "/find-product-iframe")
    haveLayout = false;

  return (
    <AudienceProvider>
      <Layout
        minimal={isFormLandingPage}
        haveLayout={haveLayout} >
        {element}
      </Layout>
    </AudienceProvider>
  );
};

export const wrapPageElement = ({ element, props }) => (
  <LayoutWrapper element={element} props={props} />
);