import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './results.module.scss';

import Logo from './../../images/logo.svg';

/**
 * Built with help from ChatGPT
 * 
 * @returns jsx
 */
const SearchResults = ({
    query,
    results
}) => {

    //Make sure we have a query
    if (!query || query.length === 0 )
        return <div><p>Let's help you find what you need. Enter a search term above.</p></div>

    const queryWords = query.trim().toLowerCase().split(' ');

    const filteredData = results
        .map((node) => {
            const { path, pageContext } = node;
            const title = (pageContext?.title ?? '').toLowerCase();
            const searchDescription = (pageContext?.searchDescription ?? '').toLowerCase();

            // Calculate the rank based on number of matched words in title and searchDescription
            let titleRank = 0;
            let descriptionRank = 0;
            let titleMatchCount = 0;
            for (const word of queryWords) {
                if (word.length > 0) { // Stop empty words e.g. if a user spaces
                    let wordStem = word;
                    if (word.endsWith('s') && word.length > 1) {
                        wordStem = word.slice(0, -1);
                    }
                    if (title.includes(word) || title.includes(wordStem)) {
                        titleRank++;
                        titleMatchCount++;
                    }
                    if (searchDescription.includes(word) || searchDescription.includes(wordStem)) {
                        descriptionRank++;
                    }
                }
            }

            let percentageDenominator = queryWords.length - titleMatchCount + 1;

            let titleMatchPercent = 0;

            if (percentageDenominator > 0)
                titleMatchPercent = titleMatchCount / percentageDenominator;

            return {
                path,
                pageContext,
                titleRank,
                titleMatchCount,
                descriptionRank,
                titleMatchPercent,
                wordCount: queryWords.length,
                percentageDenominator

            };
        })
        .filter((node) => {

            if (node.pageContext?.searchSkip)
                return false;

            if (!node.pageContext.title)
                return false;

            if (node.titleRank + node.descriptionRank <= 0)
                return false;
            
            if (node.pageContext.currentPage > 1)
                return false;

            return true;
        })
        .sort((a, b) => {
            //Put product categories highier
            if (b.titleMatchPercent > .7 && b.pageContext.pageType === 'product category' && a.pageContext.pageType !== 'product category') {
                return 1;
            }
            else if (a.titleMatchPercent > .7 && a.pageContext.pageType === 'product category' && b.pageContext.pageType !== 'product category')
                return -1;
            //Put products highier
            if (b.titleMatchPercent > .5 && b.pageContext.pageType === 'product' && a.pageContext.pageType !== 'product') {
                return 1;
            }
            else if (a.titleMatchPercent > .5 && a.pageContext.pageType === 'product' && b.pageContext.pageType !== 'product')
                return -1;
            // Sort by title match percentage if the rank is the same
            if ((b.titleMatchPercent > .8 || a.titleMatchPercent > .8) && b.titleMatchPercent - a.titleMatchPercent !== 0) {
                    
                return b.titleMatchPercent - a.titleMatchPercent;
            }

            // Sort by rank first
            if (b.titleRank - a.titleRank !== 0)
                return b.titleRank - a.titleRank;
            if (b.descriptionRank - a.descriptionRank !== 0)
                return b.descriptionRank - a.descriptionRank;

            // Sort by title if the rank is the same
            const aTitle = (a.pageContext?.title ?? '').toLowerCase();
            const bTitle = (b.pageContext?.title ?? '').toLowerCase();

            if (aTitle < bTitle)
                return -1;
            else if (aTitle > bTitle)
                return 1;

            return 0;
        });

    //If there are no results, let's tell them rather than leaving it blank
    if (filteredData.length === 0 )
    return <div><p>We're sorry, no results were found. Please try a different search.</p></div>

    return (
        <div>
            <ul className={styles.results}>
                {filteredData.map((node) => {
                    return (
                        <li key={node.path} className={styles.result}>
                            <a href={node.path} className={styles.resultWrap}>
                                <div className={styles.image}>
                                    {(node.pageContext.searchImage) ? <GatsbyImage image={getImage(node.pageContext.searchImage)} objectFit={ (node.pageContext.searchImagePosition) ? node.pageContext.searchImagePosition : "contain"} alt='' /> : <img src={Logo} className={styles.imageFallback} />}
                                </div>
                                <div>
                                    <h3>{node.pageContext.title || node.path}</h3>
                                    {node.pageContext && <p>{node.pageContext.searchDescription}</p>}
                                    <p className={styles.readMore}>Read more</p>
                                </div>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default SearchResults;