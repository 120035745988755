import React from 'react';
import * as styles from './index.module.scss';

import { FooterTop, FooterAwards, FooterPartnerships } from './../footer-top/index';
import { FooterBottom } from './../footer-bottom/index';

export const FooterSections = ({ data }) => {

  const {
    contentfulFooter
  } = data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        {contentfulFooter.footerSections.references.map((section, index) => (
          <FooterTop key={`footer-top-section-${index}`} data={section} />
        ))}
        <FooterAwards />
        <FooterPartnerships />
      </div>
      <FooterBottom data={contentfulFooter.footerSocials.references[0].socialMedia.references} />
    </div>
  )
}