module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{},"allPageHeaders":[],"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ideal Heating | New Boilers, Heat Pumps & Heating Controls","short_name":"Ideal Heating","start_url":"/","background_color":"#95C11F","theme_color":"#000","display":"standalone","icon":"src/images/icon_48x48.svg","icons":[{"src":"src/images/icon_48x48.svg","sizes":"48x48","type":"image/svg+xml"},{"src":"src/images/icon_192x192.svg","sizes":"192x192","type":"image/svg+xml"},{"src":"src/images/icon_512x512.svg","sizes":"512x512","type":"image/svg+xml"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7404cc9193e36dbe2c89a81b2a69dc1c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KQF936M","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
