import React, {useState, useRef, useEffect} from 'react';

import {Modal} from './index';
import SearchResults from './../search/results';
import { StaticQuery, graphql } from 'gatsby';

import * as styles from './search.module.scss';

import SearchIcon from './../../images/magnifying-glass-solid.png';

const SearchModal = () => {
    const [modalActive, setModalActive] = useState(false);
    const [query, setQuery] = useState(false);
    let searchInput = useRef(null);

    useEffect(() => {
        if (searchInput !== null && searchInput.current !== null) {
            searchInput.current.focus();
        }
    })
    
    return (
        <>
            <button onClick={() => {
                setModalActive(!modalActive)
                setQuery(false);
            }} className={styles.confirm}><img className={styles.image} src={SearchIcon}/></button>
            <Modal
                setVisible={setModalActive}
                visible={modalActive}
                orientation='landscape'
                background={"#eee"}
                size='fullscreen' 
                closeButtonBottom={true} >
                <div>
                    <input
                        ref={searchInput}
                        className={styles.searchBar}
                        type="text"
                        placeholder="Search..."
                        value={(query) ? query : ''}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    <StaticQuery
                        query={graphql`
                            query {
                                allSitePage {
                                    nodes {
                                        path
                                        pageContext
                                    }
                                }
                            }
                        `} render={data => query !== false && <SearchResults query={query} results={data.allSitePage.nodes} />} />
                </div>
            </Modal>
        </>
    );
}

export default SearchModal;