import React, { useEffect } from "react";

import AudienceContext from "./audienceContext";
import useAudiences from "../hooks/useAudiences";

const AudienceProvider = ({ children }) => {
    const audienceContext = useAudiences("Homeowners");

    return (
        <AudienceContext.Provider value={audienceContext}>
            { children }
        </AudienceContext.Provider>
    );
};

export default AudienceProvider;