import React from "react";

import logo from "./../../../images/logo.svg";

import * as styles from "../lib/header-sections/index.module.scss";

const MinimalHeader = () => (
    <div className={styles.wrapper}>
        <div className={`${styles.col} ${styles.centreLogo}`}>
          <a href="/" className={styles.link}>
            <img className={styles.logo} src={logo} alt="Ideal Heating" />
          </a>
        </div>
    </div>
);

export default MinimalHeader;