import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const AppDownload = ({ 
    appleStoreDownloadIcon, 
    appleStoreDownloadLink, 
    appleStoreHeading, 
    googlePlayStoreDownloadIcon, 
    googlePlayStoreDownloadLink, 
    googlePlayStoreHeading,
    useSmallHeadings
}) => (
    <div>
        <div>
            <a href={appleStoreDownloadLink}>
                <GatsbyImage image={getImage(appleStoreDownloadIcon)} objectFit="cover" alt={appleStoreHeading} />
            </a>
            {useSmallHeadings ? <p>{ appleStoreHeading }</p> : <h2>{ appleStoreHeading }</h2>}
        </div>
        <div>
            <a href={googlePlayStoreDownloadLink}>
                <GatsbyImage image={getImage(googlePlayStoreDownloadIcon)} objectFit="cover" alt={googlePlayStoreHeading} />
            </a>
            {useSmallHeadings ? <p>{ googlePlayStoreHeading }</p> : <h2>{ googlePlayStoreHeading }</h2>}
        </div>
    </div>
);

export default AppDownload;