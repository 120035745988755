import React from 'react';

import * as styles from './index.module.scss';

export const Modal = ({
    children, setVisible, visible, orientation = 'portrait', size = 'medium', background = false, closeButtonBottom = false, fixedWidth = false
}) => {
    const getModalDimensionClasses = (orientation, size) => {
        var orientationString = '';
        switch (orientation) {
            case 'landscape':
                orientationString = styles.modalCardLandscape;
                break;
            case 'portrait':
            default:
                orientationString = styles.modalCardPortrait;
                break;
        }

        var sizeString = '';
        switch (size) {
            case 'sm':
            case 'small':
                sizeString = styles.modalCardSmall;
                break;
            case 'default':
            case 'md':
            case 'medium':
            default:
                sizeString = styles.modalCardMedium;
                break;
            case 'medium-large':
            case 'md-lg':
            case 'mid-large':
                sizeString = styles.modalCardMediumLarge;
                break;
            case 'big':
            case 'lg':
            case 'large':
                sizeString = styles.modalCardLarge;
                break;
            case 'fullscreen':
                sizeString = styles.modalCardFull;
                break;
        }

        return ` ${orientationString} ${sizeString}`;
    }

    // Let's stop the user scrolling
    if (typeof document !== 'undefined' && visible)
        document.body.classList.add('stop-scrolling');
    else if (typeof document !== 'undefined' && !visible)
        document.body.classList.remove('stop-scrolling');

    return (
        <div 
            className={(visible) ? `${styles.modal} ${styles.modalVisible}` : `${styles.modal}`}
            onClick={(e) => e.target.classList.contains(styles.modal) || e.target.classList.contains(styles.modalCardClose) ? setVisible(!visible) : null}
        >
            <div
                className={styles.modalCard + (size === 'fullscreen' ? ` ${styles.fullScreenWrapper}` : '')}
                style={{
                    background: background
                }} >
                <div className={styles.modalCardInner + getModalDimensionClasses(orientation, size)}>
                    {children}
                </div>
                <span 
                    className={(closeButtonBottom === false) ? styles.modalCardClose : `${styles.modalCardClose} ${styles.modalCardCloseBottom}`}
                    onClick={() => setVisible(!visible)}
                >x{(closeButtonBottom ? ' Close' : '')}</span>
            </div>
        </div>
    );
}