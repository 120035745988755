import React from "react";

import { FooterBottom } from "../lib/footer-bottom";
import { FooterPartnerships } from "../lib/footer-top";

import * as styles from "../lib/footer-sections/index.module.scss";

const MinimalFooter = ({ data }) => {
    const { footerSocials } = data?.contentfulFooter;

    return (
        <div className={styles.wrapper}>
            <FooterPartnerships minimal={true} />
            <FooterBottom data={footerSocials?.references[0]?.socialMedia?.references} />
        </div>
    );
}

export default MinimalFooter;