import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link } from 'gatsby';

import RichText from '../../lib/contentful/rich-text';

import * as styles from './index.module.scss';

const CallToActionBanner = () => {
    const data = useStaticQuery(graphql`
        query CTABannerQuery {
            allContentfulCtaBanner (limit: 1, filter: {node_locale: {eq: "en-US"}}) {
                edges {
                    node {
                        items {
                            id
                            image {
                                file {
                                    url
                                }
                                gatsbyImageData(
                                    width: 100,
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                            content {
                                raw
                            }
                            hyperlink {
                                alt
                                openInANewTab
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const { allContentfulCtaBanner } = data;

    if (allContentfulCtaBanner.edges.length === 0)
        return null;

    const bannerData = allContentfulCtaBanner.edges[0].node;
    const { items } = bannerData;

    return (
        <div className={ styles.ctaBanner }>
            <div className={ styles.ctaBannerWrapper }>
                {items.map(item => 
                    <Item
                        key={`cta-item-${ item.id }`} 
                        {...item} 
                    />
                )}
            </div>
        </div>
    );
};

const Item = ({ image, content, hyperlink, externalLink = true }) => {
    const imageData = getImage(image);
    const imageUrl = image?.file?.url;

    // Create a function to determine the type of anchor tag to use based on `externalLink` and `openInANewTab`
    const AnchorTag = ({ children }) => {
        const { url, openInANewTab } = hyperlink;
        const targetProps = openInANewTab ? { target: "_blank", rel: "noopener noreferrer" } : {};

        return externalLink ? (
            <a href={url} {...targetProps}>{children}</a>
        ) : (
            <Link to={url} {...targetProps}>{children}</Link>
        );
    };

    return (
        <AnchorTag>
            <div className={styles.ctaBannerItem}>
                {imageData ? (
                    <GatsbyImage image={imageData} alt={`Banner item icon`} />
                ) : imageUrl ? (
                    <img src={imageUrl} alt={`Banner item icon`} />
                ) : null}
                <span className={styles.ctaBannerItemSpan}>
                    {RichText(content)}
                </span>
            </div>
        </AnchorTag>
    );
};

export default CallToActionBanner;