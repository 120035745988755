import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Button } from "../../../buttons";
import { BannerCardType } from "../../../banner-block/banner-card/lib/banner-card-type";
import ChevronRightIcon from './../../../../lib/fontawesome/ChevronIcons/Right';
import ChevronBottomIcon from './../../../../lib/fontawesome/ChevronIcons/Bottom';

import * as styles from './index.module.scss';

const mobileMenuWidth = 1099;

export const HeaderSectionBottom = ({ 
  menuItems,
  menuState,
  setMenuState
}) => (
  <ul className={styles.bottom + " " + ((menuState === true) ? styles.active : '') }>
    <button
      className={styles.close}
      onClick={() => setMenuState(false)}
    >Close</button>
    <div
      className={styles.overlay}
      onClick={() => setMenuState(false)} />
    {menuItems.map((menuItem, index) => <MenuItem key={`header-navigation-${menuItem.id}`} {...menuItem} level={0} menuState={menuState} />)}
  </ul>
)

const MenuItem = ({
  menuState,
  id,
  image,
  level,
  description,
  url,
  openInANewTab,
  title,
  subMenu,
  template,
  styleAsAButton,
  featuredBlocks,
  parent
}) => {
  const [subNavState,setSubNavState] = useState(false);

  useEffect(() => {
    if (subNavState === true && menuState === false)
      setSubNavState(false);
  })

  //Get rid of spaces and hypthens
  let subMenuTemplateClassName = template && template.length ? template.split(" ").join("").split('-').join('') : '';
  return (
    <li className={`${styles.listItem} ${styles[`listItem_${subMenuTemplateClassName}`]} ${styles[`listItem${level}`]}` + ( styleAsAButton === 'Yes' ? ` ${styles.listItem_Button}` : '' ) + (subNavState ? ` ${styles.open}` : '') + (parent === true ? ` ${styles.parentLink}` : '') }>
      {styleAsAButton === 'Yes' ? (
        <Button
          href={url} 
          content={title} 
          size='small'
          additionalClasses={styles.button} />
      ) : (
        <a
          href={url}
          className={`${styles.bottomLink} ${styles[`bottomLink${level}`]}`}
          target={(openInANewTab === 'Yes') ? '_blank' : '_self'}
          onClick={(e => {
            if (typeof window === 'undefined')
              return;

            if (window.innerWidth > mobileMenuWidth)
              return;

            if (subMenu && subMenu.length > 0) {
              e.preventDefault();
              setSubNavState(true);
            }

            return;
          })}>
            {image && <GatsbyImage image={getImage(image)} objectFit='contain' alt='' />}
            <span>
              {title}
              {description && <p>{description}</p>}
            </span>
            {subMenu && subMenu.length > 0 && level === 0 && <div className={styles.mobileIcon}><ChevronRightIcon /></div>}
            {subMenu && subMenu.length > 0 && level === 0 && <div className={styles.desktopIcon}><ChevronBottomIcon /></div>}
        </a>
      )}
      {subMenu && subMenu.length > 0 && (
        <div className={`${styles.subNav} ${styles[`subNav_${subMenuTemplateClassName}`]} ${styles[`subNav${level}`]}`}>
          <button
            className={styles.back}
            onClick={() => setSubNavState(false)}
          >Back</button>
          <div className={styles.blocks}>
            {featuredBlocks && featuredBlocks.length > 0 && featuredBlocks.map(block => <BannerCardType key={`header-nav-${id}-${level}-${block.id}`} {...block} titleHtmlTag={`span`} />)}
          </div>
          <ul className={`${styles[`subNav${level}`]} subNav${level}`}>
            {level === 0 && (
              <MenuItem
                key={`header-navigation-${id}-level-${level}-mobile`}
                id={id}
                level={level + 1}
                url={url}
                openInANewTab={openInANewTab}
                parent={true}
                title={title}
                menuState={menuState} />
            )}
            {subMenu.map(menuItem => <MenuItem key={`header-navigation-${menuItem.id}-level-${level}`} {...menuItem} level={level + 1} menuState={menuState} />)}
          </ul>
        </div>
      )}
    </li>
  )
}