import React from 'react';

import * as styles from './index.module.scss';
import { parseTag } from '../../../../../../../lib/contentful/rich-text';

export const BannerCardContentRight = ({ 
  sideImage,
  productFeatures,
  isProductFeatures,
  titleHtmlTag
}) => {
  return (
    <>
      {sideImage !== null &&
        <img className={styles.side_image} src={sideImage.url} alt={sideImage.title} />
      }
    
      {productFeatures !== null && isProductFeatures === true &&
        <>
          { parseTag(titleHtmlTag, <>Features</>) }
          <ul>
            {productFeatures.map((feature, index) => 
              <li key={`product-features-${index}`}>{feature}</li>
            )}
          </ul>
        </>
      }
    </>
  )
}