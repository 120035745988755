import { useState, useEffect } from "react";

const useAudiences = (initialValue) => {
    const key = "ih-active-audience";
    const isBrowser = typeof window !== "undefined";
    const [audienceStatus, setAudienceStatus] = useState(false);

    const getAudienceFromSlug = () => {
        if (isBrowser) {
            const pathSegments = window.location.pathname.split("/").filter(Boolean);
            const slug = pathSegments.length > 0 ? pathSegments[0] : null;
    
            if (slug)
                return parseAudience(slug);
        }

        return null;
    };    

    const getAudienceFromQueryString = () => {
        if (isBrowser) {
            const searchParams = new URLSearchParams(window.location.search);

            return parseAudience(searchParams.get("audience"));
        }

        return null;
    };

    const getInitialAudience = () => {
        setAudienceStatus(true);
    
        const audienceFromSlug = getAudienceFromSlug();
        if (audienceFromSlug)
            return audienceFromSlug;
    
        const audienceFromQuery = getAudienceFromQueryString();
        if (audienceFromQuery)
            return audienceFromQuery;
    
        if (isBrowser) {
            try {
                const item = window.localStorage.getItem(key);
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                console.log(error);
            }
        }
    
        return initialValue;
    };

    const [activeAudience, setActiveAudience] = useState();

    useEffect(() => setActiveAudience(getInitialAudience()), []);

    useEffect(() => {
        if (isBrowser && audienceStatus) {
            try {
                window.localStorage.setItem(key, JSON.stringify(activeAudience));
            } catch (error) {
                console.log(error);
            }
        }
    }, [activeAudience,audienceStatus]);

    return { activeAudience, setActiveAudience };
};

export const parseAudience = (audience) => {
    switch (audience) {
        case "installer":
        case "installers":
        case "Installer":
        case "installers":
        case "Installers":
            return "Installers";
        case "New-Build":
        case "new-build":
        case "New-build":
        case "new-Build":
            return "New Build";
        case "Social-Housing":
        case "social-housing":
        case "Social-housing":
        case "social-Housing":
            return "Social Housing";
        case "homeowners":
        case "Homeowners":
        case "home-owners":
            return "Homeowners";
    }
};

export default useAudiences;