// extracted by mini-css-extract-plugin
export var awards = "index-module--awards--ce99e";
export var awardsFullLogo = "index-module--awardsFullLogo--39427";
export var awardsLogo = "index-module--awardsLogo--558d6";
export var awardsLogoSVG = "index-module--awardsLogoSVG--ea42b";
export var item = "index-module--item--d2035";
export var link = "index-module--link--f26a8";
export var minimalAwards = "index-module--minimalAwards--ec235";
export var section = "index-module--section--da5bf";
export var small = "index-module--small--2dd0f";
export var spaceBetween = "index-module--spaceBetween--dff93";