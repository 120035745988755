import React, { useEffect } from 'react';

const LiveChat = () => {
  let integrationID = process.env.GATSBY_LIVE_CHAT_INTEGRATION_ID;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.src = "https://cdn.prod.uk.five9.net/static/stable/chat/wrapper/index.js";
      script.async = true;

      document.head.appendChild(script);

      script.onload = () => {
        if (window.F9 && window.F9.Chat && window.F9.Chat.Wrapper) {
          window.F9.Chat.Wrapper.init({
            cdn: "prod-uk",
            useBusinessHours: true,
            languages: {
              "enabled": false,
              "backgroundColor": "#95C11F"
            },
            l10n: {
              "en": {
                "messenger": {
                  "customText": {}
                },
                "systemMessages": {
                  "transferredToParticipant": "The chat has been transferred to {name}.",
                  "transferredToGroup": "That chat has been transferred to group {group}."
                },
                "captureFields": [{
                  "k": "name",
                  "l": "Name",
                  "p": "Enter your name..."
                }, {
                  "k": "email",
                  "l": "Email Address",
                  "p": "Enter your email..."
                }, {
                  "k": "Question",
                  "l": "Question",
                  "p": "What can we help you with today?"
                }]
              }
            },
            prepopulatedFields: [{
              "k": "campaign",
              "v": "IHL_Consumer_WC"
            }],
            messenger: {
              "integrationId": `${integrationID}`,
              "soundNotificationEnabled": true,
              "transcriptPrintingEnabled": false,
              "menuItems": {
                "imageUpload": true,
                "fileUpload": true,
                "shareLocation": true
              },
              "embedded": false,
              "businessIconUrl": "https://storage.googleapis.com/10183/five9/chat-widget/images/Ideal%20Heating%20Logo%20V2.jpg",
              "browserStorage": "sessionStorage",
              "fixedHeader": false,
              "displayStyle": "button",
              "buttonWidth": "58px",
              "buttonHeight": "58px",
              "customColors": {
                "brandColor": "95C11F",
                "conversationColor": "0099ff",
                "actionColor": "0099ff"
              }
            },
            clearMessagesTimeout: 3
          });
        } else {
          console.error('F9 script loaded, but F9 object is not available');
        }
      };

      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  return (
    <div id="fn-chat-iframe-container"></div>
  );
};

export default LiveChat;