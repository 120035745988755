import React, {useState} from "react";
import AudiencesBar from "../../../audiences/top-bar";
import { HeaderSectionBottom } from "../header-section-bottom";
import { HeaderSectionTop } from "../header-section-top";

import logo from './../../../../images/logo.svg';
import MenuIcon from "../../../../lib/fontawesome/MenuIcon";

import * as styles from './index.module.scss';

export const HeaderSections = ({ data }) => {
  const [menuState, setMenuState] = useState(false);

  const {
    contentfulMenus
  } = data;

  return (
    <>
      <AudiencesBar audiences={[
        {
          "name": "Homeowners",
          "url": "/homeowners",
          "slug": "homeowners"
        }, 
        {
          "name": "Installers",
          "url": "/installers",
          "slug": "installers"
        }, 
        {
          "name": "New Build",
          "url": "/new-build",
          "slug": "new-build"
        }, 
        {
          "name": "Social Housing",
          "url": "/social-housing",
          "slug": "social-housing"
        }
      ]} />
      <HeaderSectionTop />
      <div className={styles.wrapper}>
        <div className={styles.col}>
          <a href="/" className={styles.link}>
            <img className={styles.logo} src={logo} alt="Ideal Heating" />
          </a>
        </div>
        <div className={styles.col}>
          <HeaderSectionTop />
          <button
            className={styles.mobileMenuToggle}
            onClick={e => setMenuState(!menuState)}><MenuIcon /> Menu</button>
          <HeaderSectionBottom
            {...contentfulMenus}
            menuState={menuState}
            setMenuState={setMenuState} />        
        </div>
      </div>
    </>
  )
}