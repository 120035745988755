import React from 'react';

const TickIcon = ({fill = '#000', title = 'Tick icon', className}) => (
    <svg
        className={`${className}`}
        title={`${title}`}
        viewBox="0 0 448.04999 320.04999"
        version="1.1"
        id="svg4"
        width="448.04999"
        height="320.04999"
        xmlns="http://www.w3.org/2000/svg"
        xmlnssvg="http://www.w3.org/2000/svg"
    >
        <defs id="defs8" />
        <path
            fill={`${fill}`}
            d="m 438.675,9.375 c 12.5,12.5 12.5,32.8 0,45.3 l -256,256 c -12.5,12.5 -32.8,12.5 -45.3,0 l -128,-128 c -12.5,-12.5 -12.5,-32.8 0,-45.3 12.5,-12.5 32.8,-12.5 45.3,0 l 105.4,105.3 233.4,-233.3 c 12.5,-12.5 32.8,-12.5 45.3,0 z"
            id="path2"
        />
    </svg>
);

export default TickIcon;