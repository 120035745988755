import React from "react";

import SearchModal from './../../../modal/search';

import * as styles from './index.module.scss';

import UK from './../../../../images/uk.png';
import Ireland from './../../../../images/ireland.png';
import Phone from './../../../../images/phone.png';
import User from './../../../../images/user.png';
import AudiencesBar from "../../../audiences/top-bar";

export const HeaderSectionTop = () => {
  return (
    <div className={styles.top}>
      <a className={styles.icon_link} href="https://gauk.my.site.com/support/s/login/" alt="" target="_blank">
        <img className={`${styles.icon} ${styles.image}`} src={User} />
        Login
      </a>
      <a className={styles.icon_link} href="tel:01482498660" alt="">
        <img className={`${styles.icon} ${styles.image}`} src={Phone} />
        01482 498660
      </a>
      <SearchModal />
      <a href="/" className={`${styles.location} ${styles.locationUk}`}><img className={styles.image} src={UK} /></a>
      <AudiencesBar audiences={[
        {
          "name": "Homeowners",
          "url": "/homeowners",
          "slug": "homeowners"
        }, 
        {
          "name": "Installers",
          "url": "/installers",
          "slug": "installers"
        }, 
        {
          "name": "New Build",
          "url": "/new-build",
          "slug": "new-build"
        }, 
        {
          "name": "Social Housing",
          "url": "/social-housing",
          "slug": "social-housing"
        }
      ]} mobile />
      <a href="/ireland" className={`${styles.location} ${styles.locationIre}`}><img className={styles.image} src={Ireland}/></a>
    </div>
  )
}