// extracted by mini-css-extract-plugin
export var accordionItem = "index-module--accordionItem--dcb32";
export var accordionItemChildren = "index-module--accordionItemChildren--78cb6";
export var accordionItemContent = "index-module--accordionItemContent--e1c38";
export var accordionItemContentOpen = "index-module--accordionItemContentOpen--55860";
export var accordionItemContentVerbose = "index-module--accordionItemContentVerbose--8901f";
export var accordionItemTitle = "index-module--accordionItemTitle--2692c";
export var accordionItemTitleOpen = "index-module--accordionItemTitleOpen--ab166";
export var accordionItemTitleVerbose = "index-module--accordionItemTitleVerbose--40f06";
export var accordionItemTitleVerboseOpen = "index-module--accordionItemTitleVerboseOpen--d1bde";
export var small = "index-module--small--60153";